import { Level } from '@nsalaun/ng-logger';

import { ApplicationNamesEnum, EnvironmentModel, StagesEnum } from '@svg/environment';

export const ENVIRONMENT: EnvironmentModel = {
	apiInfix: 'api.staging.mysvg.de',
	applicationName: ApplicationNamesEnum.mySVG,
	clientId: 'mySVG',
	enabledUpdateServiceWorker: true,
	logLevel: Level.INFO,
	name: StagesEnum.STAGING,
	scope: 'openid',
	sentry: {
		url: 'https://1a4e9613a3e947c0ba5596c9f69d2744@o1347961.ingest.sentry.io/6631246',
		// sample all transactions on staging
		tracesSampleRate: 1,
	},
	tokenIssuer: 'https://staging.svg-auth.de/realms/mysvg',
};
