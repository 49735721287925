import { NgModule } from '@angular/core';

import { AccountMasterDataModule } from '@mysvg/api/account-master-data';
import { AuthModule } from '@mysvg/api/auth';
import { BpmModule } from '@mysvg/api/bpm';
import { ContactDataModule } from '@mysvg/api/contact-data';
import { DashboardConfigModule } from '@mysvg/api/dashboard-config';
import { DhlAddressesModule } from '@mysvg/api/dhl-addresses';
import { DocumentModule } from '@mysvg/api/document';
import { DocumentGeneratorModule } from '@mysvg/api/document-generator';
import { FinancialGuaranteesModule } from '@mysvg/api/financial-guarantees';
import { FuelCardModule } from '@mysvg/api/fuel-card';
import { FuelCardComposerModule } from '@mysvg/api/fuel-card-composer';
import { FuelCardShellListPricesModule } from '@mysvg/api/fuel-card-shell-list-prices';
import { KmasterBoxManagementModule } from '@mysvg/api/kmaster-box-management';
import { MailModule } from '@mysvg/api/mail';
import { MessagingModule } from '@mysvg/api/messaging';
import { OperationFiguresModule } from '@mysvg/api/operation-figures';
import { PaymentModule } from '@mysvg/api/payment';
import { PiaModule } from '@mysvg/api/pia';
import { SvgsModule } from '@mysvg/api/svgs';
import { TollBoxModule } from '@mysvg/api/toll-box';
import { TollComposerModule } from '@mysvg/api/toll-composer';
import { TollEurotollDmautModule } from '@mysvg/api/toll-eurotoll-dmaut';
import { VehicleModule } from '@mysvg/api/vehicle';
import { VignetteModule } from '@mysvg/api/vignette';
import { ExcelModule } from '@z-service/api/excel';
import { ReleaseNotesModule } from '@z-service/api/release-notes';

import { API_BASE_URLS } from '../environments/api-base-urls';

@NgModule({
	imports: [
		AccountMasterDataModule.forRoot({ rootUrl: API_BASE_URLS.accountMasterData }),
		AuthModule.forRoot({ rootUrl: API_BASE_URLS.auth }),
		BpmModule.forRoot({ rootUrl: API_BASE_URLS.bpm }),
		ContactDataModule.forRoot({ rootUrl: API_BASE_URLS.contactData }),
		DashboardConfigModule.forRoot({ rootUrl: API_BASE_URLS.dashboardConfig }),
		DhlAddressesModule.forRoot({ rootUrl: API_BASE_URLS.dhlAddresses }),
		DocumentGeneratorModule.forRoot({ rootUrl: API_BASE_URLS.documentGenerator }),
		DocumentModule.forRoot({ rootUrl: API_BASE_URLS.document }),
		ExcelModule.forRoot({ rootUrl: API_BASE_URLS.excel }),
		FinancialGuaranteesModule.forRoot({ rootUrl: API_BASE_URLS.financialGuarantees }),
		FuelCardComposerModule.forRoot({ rootUrl: API_BASE_URLS.fuelCardComposer }),
		FuelCardModule.forRoot({ rootUrl: API_BASE_URLS.fuelCard }),
		FuelCardShellListPricesModule.forRoot({ rootUrl: API_BASE_URLS.fuelCardShellListPrices }),
		KmasterBoxManagementModule.forRoot({ rootUrl: API_BASE_URLS.kmasterBoxManagement }),
		MailModule.forRoot({ rootUrl: API_BASE_URLS.mail }),
		MessagingModule.forRoot({ rootUrl: API_BASE_URLS.messaging }),
		OperationFiguresModule.forRoot({ rootUrl: API_BASE_URLS.operationFigures }),
		PaymentModule.forRoot({ rootUrl: API_BASE_URLS.payment }),
		PiaModule.forRoot({ rootUrl: API_BASE_URLS.pia }),
		ReleaseNotesModule.forRoot({ rootUrl: API_BASE_URLS.releaseNotes }),
		SvgsModule.forRoot({ rootUrl: API_BASE_URLS.svgs }),
		TollBoxModule.forRoot({ rootUrl: API_BASE_URLS.tollBox }),
		TollEurotollDmautModule.forRoot({ rootUrl: API_BASE_URLS.tollEurotollDmaut }),
		TollComposerModule.forRoot({ rootUrl: API_BASE_URLS.toll }),
		VehicleModule.forRoot({ rootUrl: API_BASE_URLS.vehicle }),
		VignetteModule.forRoot({ rootUrl: API_BASE_URLS.vignette }),
	],
})
export class ApiModule {}
