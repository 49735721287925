/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AuthContext } from '../models/auth-context';
import { BusinessSectorPermission } from '../models/business-sector-permission';
import { CustomerInfo } from '../models/customer-info';
import { getActivityTree } from '../fn/auth-controller/get-activity-tree';
import { GetActivityTree$Params } from '../fn/auth-controller/get-activity-tree';
import { getAuthContext } from '../fn/auth-controller/get-auth-context';
import { GetAuthContext$Params } from '../fn/auth-controller/get-auth-context';
import { getBusinessSectorsForCustomer } from '../fn/auth-controller/get-business-sectors-for-customer';
import { GetBusinessSectorsForCustomer$Params } from '../fn/auth-controller/get-business-sectors-for-customer';
import { getCustomerInfo } from '../fn/auth-controller/get-customer-info';
import { GetCustomerInfo$Params } from '../fn/auth-controller/get-customer-info';
import { getCustomerInfoByPortalUid } from '../fn/auth-controller/get-customer-info-by-portal-uid';
import { GetCustomerInfoByPortalUid$Params } from '../fn/auth-controller/get-customer-info-by-portal-uid';
import { getUserContext } from '../fn/auth-controller/get-user-context';
import { GetUserContext$Params } from '../fn/auth-controller/get-user-context';
import { getUserContextByGroupId } from '../fn/auth-controller/get-user-context-by-group-id';
import { GetUserContextByGroupId$Params } from '../fn/auth-controller/get-user-context-by-group-id';
import { getUsersActivities } from '../fn/auth-controller/get-users-activities';
import { GetUsersActivities$Params } from '../fn/auth-controller/get-users-activities';
import { getUsersActivitiesForGroup } from '../fn/auth-controller/get-users-activities-for-group';
import { GetUsersActivitiesForGroup$Params } from '../fn/auth-controller/get-users-activities-for-group';
import { UserContext } from '../models/user-context';
import { UserGroupNode } from '../models/user-group-node';

@Injectable({ providedIn: 'root' })
export class AuthControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getUserContext()` */
  static readonly GetUserContextPath = '/auth/user-context';

  /**
   * Getting the customer info, svgInfo and userGroup for the current user identified by the JWTs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserContext()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserContext$Response(params?: GetUserContext$Params, context?: HttpContext): Observable<StrictHttpResponse<UserContext>> {
    return getUserContext(this.http, this.rootUrl, params, context);
  }

  /**
   * Getting the customer info, svgInfo and userGroup for the current user identified by the JWTs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserContext$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserContext(params?: GetUserContext$Params, context?: HttpContext): Observable<UserContext> {
    return this.getUserContext$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserContext>): UserContext => r.body)
    );
  }

  /** Path part for operation `getUserContextByGroupId()` */
  static readonly GetUserContextByGroupIdPath = '/auth/user-context/{groupId}';

  /**
   * Getting the customer info, svgInfo and userGroup for the current user identified by the JWTs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserContextByGroupId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserContextByGroupId$Response(params: GetUserContextByGroupId$Params, context?: HttpContext): Observable<StrictHttpResponse<UserContext>> {
    return getUserContextByGroupId(this.http, this.rootUrl, params, context);
  }

  /**
   * Getting the customer info, svgInfo and userGroup for the current user identified by the JWTs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserContextByGroupId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserContextByGroupId(params: GetUserContextByGroupId$Params, context?: HttpContext): Observable<UserContext> {
    return this.getUserContextByGroupId$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserContext>): UserContext => r.body)
    );
  }

  /** Path part for operation `getCustomerInfo()` */
  static readonly GetCustomerInfoPath = '/auth/customerInfo';

  /**
   * getting the customer info for a given svgCustomerNumber or customer alias
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerInfo$Response(params?: GetCustomerInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerInfo>> {
    return getCustomerInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * getting the customer info for a given svgCustomerNumber or customer alias
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerInfo(params?: GetCustomerInfo$Params, context?: HttpContext): Observable<CustomerInfo> {
    return this.getCustomerInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerInfo>): CustomerInfo => r.body)
    );
  }

  /** Path part for operation `getCustomerInfoByPortalUid()` */
  static readonly GetCustomerInfoByPortalUidPath = '/auth/customerInfo/{portalUid}';

  /**
   * getting the customer info for a given customer portal uid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerInfoByPortalUid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerInfoByPortalUid$Response(params: GetCustomerInfoByPortalUid$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerInfo>> {
    return getCustomerInfoByPortalUid(this.http, this.rootUrl, params, context);
  }

  /**
   * getting the customer info for a given customer portal uid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerInfoByPortalUid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerInfoByPortalUid(params: GetCustomerInfoByPortalUid$Params, context?: HttpContext): Observable<CustomerInfo> {
    return this.getCustomerInfoByPortalUid$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerInfo>): CustomerInfo => r.body)
    );
  }

  /** Path part for operation `getBusinessSectorsForCustomer()` */
  static readonly GetBusinessSectorsForCustomerPath = '/auth/businessSectors/{portalUid}';

  /**
   * getting the business sectors for a given customer portal uid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBusinessSectorsForCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBusinessSectorsForCustomer$Response(params: GetBusinessSectorsForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BusinessSectorPermission>>> {
    return getBusinessSectorsForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * getting the business sectors for a given customer portal uid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBusinessSectorsForCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBusinessSectorsForCustomer(params: GetBusinessSectorsForCustomer$Params, context?: HttpContext): Observable<Array<BusinessSectorPermission>> {
    return this.getBusinessSectorsForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BusinessSectorPermission>>): Array<BusinessSectorPermission> => r.body)
    );
  }

  /** Path part for operation `getAuthContext()` */
  static readonly GetAuthContextPath = '/auth/auth-context';

  /**
   * Getting the customer info, svgContext, activities and activityTree for the current user identified by the JWTs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuthContext()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuthContext$Response(params?: GetAuthContext$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthContext>> {
    return getAuthContext(this.http, this.rootUrl, params, context);
  }

  /**
   * Getting the customer info, svgContext, activities and activityTree for the current user identified by the JWTs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuthContext$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuthContext(params?: GetAuthContext$Params, context?: HttpContext): Observable<AuthContext> {
    return this.getAuthContext$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthContext>): AuthContext => r.body)
    );
  }

  /** Path part for operation `getActivityTree()` */
  static readonly GetActivityTreePath = '/auth/activity-tree';

  /**
   * getting the activity tree for an authenticated user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivityTree()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivityTree$Response(params?: GetActivityTree$Params, context?: HttpContext): Observable<StrictHttpResponse<UserGroupNode>> {
    return getActivityTree(this.http, this.rootUrl, params, context);
  }

  /**
   * getting the activity tree for an authenticated user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActivityTree$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivityTree(params?: GetActivityTree$Params, context?: HttpContext): Observable<UserGroupNode> {
    return this.getActivityTree$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserGroupNode>): UserGroupNode => r.body)
    );
  }

  /** Path part for operation `getUsersActivities()` */
  static readonly GetUsersActivitiesPath = '/auth/activities';

  /**
   * getting the activites
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersActivities$Response(params?: GetUsersActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getUsersActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * getting the activites
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsersActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersActivities(params?: GetUsersActivities$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getUsersActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getUsersActivitiesForGroup()` */
  static readonly GetUsersActivitiesForGroupPath = '/auth/activities/{groupId}';

  /**
   * getting the activites for a given keycloak group
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersActivitiesForGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersActivitiesForGroup$Response(params: GetUsersActivitiesForGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getUsersActivitiesForGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * getting the activites for a given keycloak group
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsersActivitiesForGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersActivitiesForGroup(params: GetUsersActivitiesForGroup$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getUsersActivitiesForGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
