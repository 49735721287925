import { MysvgBaseUrls } from '@mysvg/utils';

import { ENVIRONMENT } from './environment';
import { LOCAL_SERVICES } from './local-services/local-services';

export const API_BASE_URLS: MysvgBaseUrls = {
	accountMasterData: LOCAL_SERVICES.accountMasterData ? 'http://localhost:14000' : `https://${ENVIRONMENT.apiInfix}/bank/1.0`,
	auth: LOCAL_SERVICES.auth ? 'http://localhost:14002' : `https://${ENVIRONMENT.apiInfix}/auth/1.0`,
	bpm: LOCAL_SERVICES.bpm ? 'http://localhost:14005' : `https://${ENVIRONMENT.apiInfix}/bpm/1.0`,
	centrifugo: LOCAL_SERVICES.centrifugo ? 'http://localhost:14006' : `https://${ENVIRONMENT.apiInfix}/centrifugo/1.0/connection/sockjs`,
	contactData: LOCAL_SERVICES.contactData ? 'http://localhost:14007' : `https://${ENVIRONMENT.apiInfix}/contact/1.0`,
	dashboardConfig: LOCAL_SERVICES.dashboardConfig ? 'http://localhost:14008' : `https://${ENVIRONMENT.apiInfix}/dashboardconfig/1.0`,
	dhlAddresses: LOCAL_SERVICES.dhlAddresses ? 'http://localhost:14009' : `https://${ENVIRONMENT.apiInfix}/dhl-addresses/1.0`,
	document: LOCAL_SERVICES.document ? 'http://localhost:14010' : `https://${ENVIRONMENT.apiInfix}/document/1.0`,
	documentGenerator: LOCAL_SERVICES.documentGenerator ? 'http://localhost:14011' : `https://${ENVIRONMENT.apiInfix}/document-generator/1.0`,
	excel: LOCAL_SERVICES.excel ? 'http://localhost:8888' : `https://${ENVIRONMENT.apiInfix}/excel/1.0`,
	financialGuarantees: LOCAL_SERVICES.financialGuarantees
		? 'http://localhost:14012'
		: `https://${ENVIRONMENT.apiInfix}/financial-guarantees/1.0`,
	fuelCard: LOCAL_SERVICES.fuelCard ? 'http://localhost:14013' : `https://${ENVIRONMENT.apiInfix}/fuel-card-administration/1.0`,
	fuelCardShellListPrices: LOCAL_SERVICES.fuelCardShellListPrices
		? 'http://localhost:14018'
		: `https://${ENVIRONMENT.apiInfix}/fuelcard-shell-listenpreise/1.0`,
	fuelCardComposer: LOCAL_SERVICES.fuelCardComposer ? 'http://localhost:14020' : `https://${ENVIRONMENT.apiInfix}/fuelcard/1.0`,
	kmasterBoxManagement: LOCAL_SERVICES.kmasterBoxManagement ? 'http://localhost:14021' : `https://${ENVIRONMENT.apiInfix}/kmaster/1.0`,
	kmasterDemoGps: `https://${ENVIRONMENT.apiInfix}/kmaster-demo-gps/1.0`,
	mail: LOCAL_SERVICES.mail ? 'http://localhost:14023' : `https://${ENVIRONMENT.apiInfix}/mail/1.0`,
	messaging: LOCAL_SERVICES.messaging ? 'http://localhost:14024' : `https://${ENVIRONMENT.apiInfix}/messaging/1.0`,
	operationFigures: LOCAL_SERVICES.operationFigures ? 'http://localhost:14025' : `https://${ENVIRONMENT.apiInfix}/operationfigure/1.0`,
	payment: LOCAL_SERVICES.payment ? 'http://localhost:14026' : `https://${ENVIRONMENT.apiInfix}/payment/1.0`,
	pia: LOCAL_SERVICES.pia ? 'http://localhost:14027' : `https://${ENVIRONMENT.apiInfix}/customer/1.0`,
	releaseNotes: LOCAL_SERVICES.releaseNotes ? 'http://localhost:13778' : `https://${ENVIRONMENT.apiInfix}/release-notes/1.0`,
	svgs: LOCAL_SERVICES.svgs ? 'http://localhost:14029' : `https://${ENVIRONMENT.apiInfix}/svgs/1.0`,
	toll: LOCAL_SERVICES.toll ? 'http://localhost:14030' : `https://${ENVIRONMENT.apiInfix}/toll/1.0`,
	tollBox: LOCAL_SERVICES.tollBox ? 'http://localhost:14031' : `https://${ENVIRONMENT.apiInfix}/toll-administration/1.0`,
	tollEurotollDmaut: LOCAL_SERVICES.tollEurotollDmaut
		? 'http://localhost:14034'
		: `https://${ENVIRONMENT.apiInfix}/toll-eurotoll-dmaut/1.0`,
	vehicle: LOCAL_SERVICES.vehicle ? 'http://localhost:14040' : `https://${ENVIRONMENT.apiInfix}/vehicle/1.0`,
	vignette: LOCAL_SERVICES.vignette ? 'http://localhost:14041' : `https://${ENVIRONMENT.apiInfix}/vignette/2.1`,
};
