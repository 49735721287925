import { EventEmitter, Inject, Injectable, InjectionToken } from '@angular/core';
import { ClrDatagridHideableColumn } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';

import { LocalTableViewOptionsService } from './local-table-view-options.service';

export const DEFAULT_COMPACT_STATE = false;
export const DEFAULT_PAGE_SIZE = 15;
export const DEFAULT_PAGE = 0;
export const COMPACT_STATE_CACHE = '_COMPACT_LS_CACHE';
export const HIDEABLE_COLUMNS_CACHE = '_COLUMNS_LS_CACHE';
export const PAGE_SIZE_CACHE = '_PAGE_SIZE_LS_CACHE_V1';
export const TABLE_VIEW_OPTIONS_KEY = new InjectionToken<string>('optionsKey');

export interface ColumnsHiddenStateType {
	column: string;
	filterName: string;
	hidden: boolean;
}

@UntilDestroy()
@Injectable()
export class TableViewOptionsService {
	compactState$: Observable<boolean>;
	pageSize$: Observable<number>;
	page$: Observable<number>;

	private columnsHiddenState$ = new BehaviorSubject<ColumnsHiddenStateType[]>([]);
	private compactStateSubject$ = new BehaviorSubject<boolean>(DEFAULT_COMPACT_STATE);
	private pageSizeSubject$ = new BehaviorSubject<number>(DEFAULT_PAGE_SIZE);
	private pageSubject$ = new BehaviorSubject<number>(DEFAULT_PAGE);

	constructor(
		@Inject(TABLE_VIEW_OPTIONS_KEY) private optionsKey: string,
		private localTableViewOptionsService: LocalTableViewOptionsService,
	) {
		this.pageSize$ = this.pageSizeSubject$.asObservable();
		this.compactState$ = this.compactStateSubject$.asObservable();
		this.page$ = this.pageSubject$.asObservable();

		this.getInitialPageSize();
		this.getInitialCompactState();
	}

	setPageSize(pageSize: number): void {
		if (!isNaN(pageSize)) {
			this.pageSizeSubject$.next(pageSize);
			this.localTableViewOptionsService.save(this.optionsKey + PAGE_SIZE_CACHE, pageSize.toString());
		}
	}

	changeCompactState(compactState: boolean): void {
		this.compactStateSubject$.next(compactState);
		this.localTableViewOptionsService.save(this.optionsKey + COMPACT_STATE_CACHE, compactState.toString());
	}

	setInitialHiddenStateFromStorage(field: string, hideableColumn: ClrDatagridHideableColumn, filterName?: string): void {
		let options: ColumnsHiddenStateType[] = this.localTableViewOptionsService.getOptions(this.optionsKey + HIDEABLE_COLUMNS_CACHE) || [];

		// check if options are corrupted or old
		if (!Array.isArray(options)) {
			options = [];
			this.localTableViewOptionsService.save(this.optionsKey, options);
		}
		this.columnsHiddenState$.next(options);

		const fieldOption = options.find((columnsHiddenStateType: ColumnsHiddenStateType) => columnsHiddenStateType.column === field);
		if (fieldOption) {
			hideableColumn.clrDgHideableColumn = { hidden: fieldOption.hidden };
		} else {
			// if column is not in options, add it with hidden value specified in html
			this.changeColumnHiddenState({ hidden: hideableColumn.clrDgHidden, column: field, filterName });
		}
	}

	/**
	 * Filters out legacy non existing fields from hidden state storage
	 * @param currentFields Input of current field names that are part of the table (hidden or not - does not matter)
	 */
	removeUnusedColumnsFromHiddenStorage(currentFields: string[]) {
		const savedColumns = this.columnsHiddenState$.value;

		const persistingColumns = savedColumns.filter((i) => currentFields.includes(i.column));

		this.columnsHiddenState$.next(persistingColumns);
		this.localTableViewOptionsService.save(this.optionsKey + HIDEABLE_COLUMNS_CACHE, persistingColumns);
	}

	changeColumnHiddenState(params: ColumnsHiddenStateType): void {
		const options = this.columnsHiddenState$.value || [];
		const filteredOptions = options.filter((value: ColumnsHiddenStateType) => value.column !== params.column);
		const nextOptions = [...filteredOptions, params];

		this.localTableViewOptionsService.save(this.optionsKey + HIDEABLE_COLUMNS_CACHE, nextOptions);
		this.columnsHiddenState$.next(nextOptions);
	}

	getColumnsHiddenState(): Observable<ColumnsHiddenStateType[]> {
		return this.columnsHiddenState$.asObservable();
	}

	private getInitialPageSize(): void {
		const pageSize: string = this.localTableViewOptionsService.getOptions(this.optionsKey + PAGE_SIZE_CACHE);

		if (pageSize && !isNaN(+pageSize)) {
			return this.pageSizeSubject$.next(+pageSize);
		} else {
			return this.pageSizeSubject$.next(DEFAULT_PAGE_SIZE);
		}
	}

	private getInitialCompactState(): void {
		const compactState: string = this.localTableViewOptionsService.getOptions(this.optionsKey + COMPACT_STATE_CACHE);

		if (!!compactState && (compactState === 'true' || compactState === 'false')) {
			return this.compactStateSubject$.next(compactState === 'true');
		} else {
			return this.compactStateSubject$.next(DEFAULT_COMPACT_STATE);
		}
	}

	setPageObservable(currentChanged: EventEmitter<number>) {
		// `page$` cannot be set directly `this.page$ = currentChanged.asObservable()`
		// because `currentChanged` is available after view init
		currentChanged
			.asObservable()
			.pipe(untilDestroyed(this))
			.subscribe((value) => this.pageSubject$.next(value));
	}
}
