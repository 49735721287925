import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import dayjs from 'dayjs';

import { NumberFormatService } from '@mysvg/utils';
import { CONTROL_ERROR_KEYS } from '@svg-frontends/error';
import { FileTypeEnum, FileTypeInfoService } from '@svg-frontends/file-upload';

export class SvgFrontendsValidators {
	static alphaNumeric(): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;
			return !value || value.toString().match(/^[a-zA-Z0-9]*$/) ? null : { [CONTROL_ERROR_KEYS.PATTERN_ALPHA_NUMERIC]: value };
		};
		return validatorFn;
	}

	static valueEquals(referenceControlName: string): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: any } => {
			const parent: FormGroup = control.parent as FormGroup;

			if (parent) {
				const referenceControl: AbstractControl = parent.get(referenceControlName);
				const referenceControlValue = referenceControl ? referenceControl.value : null;
				const value = control.value;

				return !value || !referenceControlValue || value === referenceControlValue ? null : { [CONTROL_ERROR_KEYS.VALUE_EQUALS]: value };
			} else {
				return null;
			}
		};
		return validatorFn;
	}

	static isValueSmallerOrEqualsToControl(referenceControlName: string, numberFormatService: NumberFormatService): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: any } => {
			const parent: FormGroup = control.parent as FormGroup;

			if (parent) {
				const referenceControl: AbstractControl = parent.get(referenceControlName);
				const referenceControlValue: string | null = referenceControl ? referenceControl.value : null;
				const value: string | null = control.value;

				return !value ||
					!referenceControlValue ||
					numberFormatService.fromLocaleString(value) <= numberFormatService.fromLocaleString(referenceControlValue)
					? null
					: { [CONTROL_ERROR_KEYS.VALUE_SMALLER_OR_EQUALS]: value };
			} else {
				return null;
			}
		};
		return validatorFn;
	}

	static isValueBiggerOrEqualsToControl(referenceControlName: string, numberFormatService: NumberFormatService): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: any } => {
			const parent: FormGroup = control.parent as FormGroup;

			if (parent) {
				const referenceControl: AbstractControl = parent.get(referenceControlName);
				const referenceControlValue: string | null = referenceControl ? referenceControl.value : null;
				const value: string | null = control.value;

				return !value ||
					!referenceControlValue ||
					numberFormatService.fromLocaleString(value) >= numberFormatService.fromLocaleString(referenceControlValue)
					? null
					: { [CONTROL_ERROR_KEYS.VALUE_BIGGER_OR_EQUALS]: value };
			} else {
				return null;
			}
		};
		return validatorFn;
	}

	static isDate(): ValidatorFn {
		const validatorFn: ValidatorFn = (dateControl): { [key: string]: any } => {
			const value = dateControl.value;
			const noValue = !value || value === '';
			const isValidDate = dayjs(value).isValid();

			return noValue || isValidDate ? null : { [CONTROL_ERROR_KEYS.DATE]: value };
		};
		return validatorFn;
	}

	// no error if no date is set
	static isDateAfter(start: string): ValidatorFn {
		const validatorFn: ValidatorFn = (dateControl): { [key: string]: any } => {
			// check if date valid
			const value = dateControl.value;
			const noValue = !value || value === '';

			// check if date is not before startDate
			let isAfterStart = true;

			if (dateControl.parent) {
				const startDate = dateControl.parent.controls[start];
				const noStartValue = !startDate.value || startDate.value === '';

				if (!noStartValue && startDate.status === 'VALID' && !noValue) {
					isAfterStart = dayjs(value).isSameOrAfter(dayjs(startDate.value));
				}
			}

			return isAfterStart ? null : { [CONTROL_ERROR_KEYS.END_DATE]: value };
		};
		return validatorFn;
	}

	static isNumericString(): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;
			const noValue = !value || value === '';
			return noValue || SvgFrontendsValidators.isValueNumeric(control) ? null : { [CONTROL_ERROR_KEYS.IS_NUMBER]: value };
		};
		return validatorFn;
	}

	static hasMinDecimalPlaces(decimalPlaces: number = 0): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
			const value = control.value;

			if (value) {
				const secondSplit: string = value.toString().split(',')[1];

				if (secondSplit && secondSplit.length < decimalPlaces) {
					return { [CONTROL_ERROR_KEYS.MIN_DECIMAL_PLACES]: value };
				}
			}

			return null;
		};
		return validatorFn;
	}

	static hasMaxDecimalPlaces(decimalPlaces: number = 0): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
			const value = control.value;

			if (value !== null && value !== undefined) {
				const secondSplit: string = value.toString().split(',')[1];

				if (secondSplit && secondSplit.length > decimalPlaces) {
					return { [CONTROL_ERROR_KEYS.MAX_DECIMAL_PLACES]: value };
				}
			}

			return null;
		};
		return validatorFn;
	}

	static isEmail(): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;

			if (!value) {
				// no value no error
				return null;
			} else {
				// Check whether the e-mail contains spaces and if so, a more specific error message will be displayed
				const whitespacePattern = /\s/;
				if (value.match(whitespacePattern)) {
					return { [CONTROL_ERROR_KEYS.MAIL_CONTAINS_WHITESPACES]: value };
				}

				// This will filter out most typo related invalid emails, but is by not a complete validation.
				// For more information see: https://stackoverflow.com/a/201447
				const mailPattern = /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/;
				const isMail = value.match(mailPattern);

				return isMail ? null : { [CONTROL_ERROR_KEYS.IS_MAIL]: value };
			}
		};
		return validatorFn;
	}

	/**
	 * file input always has array of files (File[])
	 * check if there is some file that has a higher file size
	 * param is in MB, so first transform to bytes, because size property of file is in bytes
	 */
	static maximumFileSize(maxFileSizeInMB: number = 5): ValidatorFn {
		const maxFileSizeInBytes = maxFileSizeInMB * 1000 * 1000;
		const validatorFn: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
			const value: File[] = control.value;

			if (value && Array.isArray(value) && value.length > 0) {
				const isSomeFileExceeding = value.some((file: File) => file.size > maxFileSizeInBytes);
				return isSomeFileExceeding ? { [CONTROL_ERROR_KEYS.MAXIMUM_FILE_SIZE_EXCEEDED]: maxFileSizeInMB } : null;
			} else {
				return null;
			}
		};
		return validatorFn;
	}

	/**
	 * file input always has array of files (File[])
	 * every files type must be matched with one allowedFileTypes (Parameter)
	 * there may not be a file, that's type is not found in allowedFileTypes
	 *
	 * [CAUTION] please use allowed file types property `[accept]` on input to tell OS's file chooser what file types should be filtered
	 */
	static allowedFileTypes(allowedFileTypes: FileTypeEnum[]): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
			const value: File[] = control.value;
			const fileTypes: string[] = FileTypeInfoService.getFileTypesFor(allowedFileTypes);

			if (value && Array.isArray(value) && value.length > 0) {
				const isSomeFileTypeNotAllowed = value.some((file: File) => fileTypes.indexOf(file.type) === -1);
				return isSomeFileTypeNotAllowed ? { [CONTROL_ERROR_KEYS.NOT_ALLOWED_FILE_TYPES]: allowedFileTypes } : null;
			} else {
				return null;
			}
		};
		return validatorFn;
	}

	private static isValueNumeric(control: AbstractControl): boolean {
		const value = control.value;
		const noValue = !value || value === '';
		const pattern = /^-?(0|([1-9]\d*))([,]?[0-9]+)?$/g;

		return !noValue ? !!value.toString().match(pattern) : false;
	}
}
